import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Sidebar, Hero } from '@layout'
import CardList from '@components/CardList'
import Card from '@components/Card'
import Divider from '@components/Divider'
import Social from '@widgets/Social'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import TableOfContentsExpanded from '@widgets/TableOfContentsExpanded'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next},
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props


  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider /> 
      {/* <Hero full>
        <Card {...post} variant='horizontal-cover-hero' omitExcerpt />
      </Hero> */}

      <Hero wide>
        <PostImage {...post} wide />
      </Hero> 
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        
        <PostHead {...post} />
        <Divider />
      </Stack>
      <Divider /> 
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <PostBody {...post} />
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            {services.graphComment && <PostCommentsGraph {...post} />}
            {services.facebookComment && (
              <PostCommentsFacebook {...post} siteUrl={siteUrl} />
            )}
            {/* <PostFooter {...{ previous, next }} /> */}
          </CardComponent>
        </Main>
        <Sidebar>
          {post.tableOfContents?.items && (
            <>
              <TableOfContentsExpanded {...post} columns={1} />
              <Divider />
            </>
          )}
          {/* <AuthorCompact author={post.author} omitTitle /> */}
          <Divider />
          {post.category && (
              <CardList
                title='Hasonló posztok'
                nodes={relatedPosts}
                variant='horizontal-aside'
                limit={4}
                omitCategory
                distinct
                aside
              />
          )}
<Divider />
          <Social />

{/* boardgames todo */}
{/* {post.category && (
            <Sticky>
              <CardList
                title='Hasonló posztok'
                nodes={relatedPosts}
                variant='horizontal-aside'
                limit={4}
                omitCategory
                distinct
                aside
              />
            </Sticky>
          )} */}

        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Post
