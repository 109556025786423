import React from 'react'
import { Link as GLink } from 'gatsby'
import { Link, Text } from 'theme-ui'
import TextList from '@components/TextList'
import PageTitle from '@components/PageTitle'

const styles = {
  item: {
    display: `inline-block`
  }
}

export const PostHead = ({ title, author, date, timeToRead, category }) => {
  const info = (
    <TextList>
      {author && author.slug && (
        <Text sx={styles.item}>
          <Link variant='mute' as={GLink} to={author.slug}>
            <strong>{author.name}</strong>
          </Link>
        </Text>
      )}
      {category && category.slug && (
        <Text sx={styles.item}>
          <Link variant='mute' as={GLink} to={category.slug}>
            <strong>{category.name}</strong>
          </Link>
        </Text>
      )}
                                          
      {date && <Text sx={styles.item}>{new Intl.DateTimeFormat("hu-HU", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }).format(new Date(date))}</Text>}
    </TextList>
  )

  return <PageTitle header={title} running={info} />
}
